const getNextInArray = (list) => {
	const listLength = filterLength((list.length-1));
	return list.map((element, index, arr) => {
		if($(element).hasClass('active'))
			return (listLength((index+1))) ? arr[0] : arr[index+1];
	}).reduce((collected, current) => {
		if(current !== undefined) collected = current;
		return collected;
	}, []);
}


const getFirstInArray = (list) => {
	return list.reduce((collected, current) => {
		if(!collected)
			collected = current;
		return collected;
	},false);
}

const filterClass = (className) => {
	return (element) => {
		return $(element).hasClass(className);
	}
}

const filterLength = (max) => {
	return (current) => {
		return (current > max);
	}
}

const removeClass = (className) => {
	return (element) => {
		return $(element).removeClass(className);
	}
}

const addClass = (className) => {
	return (element) => {
		return $(element).addClass(className);
	}
}

const getActiveElement = (list) => {
	const active = filterClass('active');
	return list.filter((element) => {
		if(active(element)) return element;
	});
}


const counter = {
	setActive: (list) => {
		const setInactive = removeClass('active');
		const setActive = addClass('active');
		return (item) => {
			list.forEach((element) => {
				if(item == element) {
					setActive(element)
				} else {
					setInactive(element);
				}
			});
		}
	},

	getList: () => { return $('.counter .content .item').toArray(); },

	getNext: (list) => {
		return list.reduce((collected, current) => {
			return collected;
		},false);
	},

	set: () => {
		const list = counter.getList();
		const navigation = $('.counter .navigation');
		const firstElement = getFirstInArray(list);
		const setActive = counter.setActive(list);

		navigation.on('click', function(e){
			e.preventDefault();
			let list = counter.getList();
			let list_reverse = counter.getList();
			let nextElement = $(e.delegateTarget).hasClass('next') ? getNextInArray(list) : getNextInArray(list_reverse.reverse());
			setActive(nextElement);
		});

		setActive(firstElement);
	}
};

module.exports = counter;