const getArtcleIds = element => parseInt(element.value); 

const filteredList = (currentList) => {
    return (element) => {
        //console.log(element);
        if(currentList.indexOf(element.id) < 0) return element;
    }
}

const getArticleName = (article) => {
    return article.names.reduce((current, element) => {
        if(element.name)
            current = element.name;    
        return current; 
    }, 'Onbekend'); 
}

const createInputs = (article) => {
    let name = getArticleName(article);
    let label = document.createElement("label");
    let input = document.createElement('input' );
    input.setAttribute('type', 'radio'); 

    return label; 
}

const getArticles = (list) => {
    return (e) => {
        // Get current articles, so we can filter them out 
        let checkboxlist = [].slice.call(e.target.previousElementSibling.querySelectorAll('input[type="radio"]'));
        let currentArticleList = checkboxlist.map(getArtcleIds);
        let filterIds = filteredList(currentArticleList);
        let filteredArticleList = salesArticleList.filter(filterIds);

        // TODO create inputs and labels based on list, 

        let inputs = filteredArticleList.map(createInputs);
        console.log(inputs);
    }
}

// fading out and removing the items from the sale import
$('.close-button').on("click", function () {
   $(this).closest('.item').fadeOut(500, function(){
       $(this).remove();
   });
});

module.exports = {
    set: () => {
        const getArticleList = getArticles(salesArticleList);
        const triggers = document.querySelectorAll('[data-sales-article-trigger="true"]'); 
       
        triggers.forEach((element) => { element.addEventListener('click', getArticleList); });
    }
}

