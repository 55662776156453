const filter = {
	active: (element) => { element.addClass('active'); },

	inactive: (element) => { element.removeClass('active'); },

	state: (element) => { return element.hasClass('active'); },

	set: (id) => {
		const element = $(id);
		const closeButton = element.find('.filter-close');

		closeButton.on('click',function(e){
			e.preventDefault();
			filter.inactive(element);
		});

		if(filter.state(element)) {
			filter.inactive(element);
		} else {
			filter.active(element);
		}
	}
};

module.exports = filter;