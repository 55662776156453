global.$ = global.jQuery = require('jquery');
require('babel-polyfill');

require('selectric');
require('bootstrap');

// load vendors used by keen
require('./theme/vendors/global');
require('./theme/vendors/custom/datatables');

window.KTUtil = require("./theme/util");
window.KTApp = require("./theme/app");
window.KTAvatar = require("./theme/avatar");
window.KTDialog = require("./theme/dialog");
window.KTHeader = require("./theme/header");
window.KTMenu = require("./theme/menu");
window.KTOffcanvas = require("./theme/offcanvas");
window.KTPortlet = require("./theme/portlet");
window.KTScrolltop = require("./theme/scrolltop");
window.KTToggle = require("./theme/toggle");
window.KTWizard = require('./theme/wizard');

require("./theme/datatable/core.datatable");
require("./theme/datatable/datatable.checkbox");
require('./theme/search');
require('./theme/layout');

function showLoader() {
  if (document.readyState === 'complete') {
    //
  } else {
    document.getElementsByTagName("BODY")[0].classList.add('kt-page--loading');
  }
}

// setTimeout(showLoader(), 1000);
const filter = require('./filter/filter');
const filterList = require('./filter/filter_list');
const counter = require('./counter/counter');
const multivalue = require('./form/multivalue');
const sales = require('./sales/sales');

const body = $('body');
const overlayId = '#overlay';

const insertHtml = (element) => {
  return (content) => {
    if ($(element).length)
      $(element).html(content);
  }
};

const setAttribute = ({element, attribute, value}) => {
  $(element).attr(attribute, value);
};

/* Set location of window */
const windowLocation = (link) => {
  location.href = link;
};

const stringHasChar = ({char, str}) => (str.indexOf(char) !== -1);

// Switch element with classname
const activator = {
  setActive: (element, classname) => {
    element.addClass(classname);
  },

  setInActive: (element, classname) => {
    element.removeClass(classname);
  },

  set: (id) => {

    let element = $(id);
    if (element.hasClass('active')) {
      activator.setActive(element, 'inactive');
      activator.setInActive(element, 'active');
      setTimeout(() => {
        activator.setInActive(element, 'inactive');
      }, 400);
    } else {
      activator.setActive(element, 'active');
    }
  }
};

// Basic overlay construction
const overlay = (options) => {
  return new Promise((resolve, reject) => {
    const element = $(overlayId);
    const elementContent = element.find('.content');
    const elementHtmlContent = insertHtml(elementContent);
    const closeButton = element.find('a[href="#close"]');

    //  Set content
    if (options.content) elementHtmlContent(options.content);
    // Set type
    // if (options.type) {
    //     setAttribute({element: element, attribute: 'data-type', value: options.type});
    // }

    // Set overlay active
    element.removeClass('info');
    element.addClass('active');
    // Position offset
    const closeButtonoffset = (element.find('.content').width() / 2) + (closeButton.width() / 2);
    // Set close listener
    closeButton.on('click touchstart', e => {
      e.preventDefault();
      element.removeClass('active');
    }).css({'margin-left': closeButtonoffset});

    return resolve(element);
  });
};

// Basic overlay construction
const info = (options) => {
  return new Promise((resolve, reject) => {
    const element = $(overlayId);
    const elementContent = element.find('.content');
    const elementHtmlContent = insertHtml(elementContent);
    const closeButton = element.find('a[href="#close"]');

    //  Set content
    if (options.content)
      elementHtmlContent(options.content);

    // Set overlay active
    element.addClass('info');
    element.addClass('active');

    // Position offset
    const closeButtonoffset = (element.find('.content').width()) + (closeButton.width() * 1.5);

    // Set close listener
    closeButton.on('click touchstart', e => {
      e.preventDefault();
      element.removeClass('active');
    }).css({'margin-left': closeButtonoffset});

    return resolve(element);
  });
};

// Delete overlay actions
const overlayDeleteList = [
  'userDelete',
  'branchDelete',
  'articleDelete',
  'recipeDelete',
  'sellunitsDelete',
  'packingunitsDelete',
  'posDelete',
  'supplierDelete',
  'branchArticleDelete',
  'supplierArticleDelete',
  'purchaseunitsDelete',
  'categoryDelete',
  'recipeArticleDelete',
  'posArticleDelete',
  'articleSellunitDelete',
  'stockmutationDelete',
  'articleSave'
];

// Delete overlay actions
const deleteConfirmationOverlay = (content) => new overlay({content: content, type: 'error'});

const overlayConfirmList = ['confirmTotal', 'scheduleShow'];
const confirmOverlay = (content) => new overlay({content: content, type: 'success'});

// Form overlay actions
const overlayFormList = ['branchesAddProduct', 'confirmCounts', 'confirmOrder', 'orderUnlock', 'confirmSale', 'updateStock', 'saleUnlock'];
const formOverlay = (content) => new overlay({content: content, type: 'error'});

const infoOverlay = (content) => new info({content: content, type: 'info'});

// Form callbacks
const callbacks = {};
//callbacks.formInit = formInit;

$(() => {

  // Select plugin - http://selectric.js.org/
  const selectric = $('select').not('.noSelectric').selectric({
    maxHeight: 250
  });

  // get buttons with class disableDubbleClick
  const disableDubbleClick = $('.disableDubbleClick');

  disableDubbleClick.on('click', function () {
    const button = $(this);
    const form = document.querySelector('form');
    const submitEvent = new Event('submit');


    html = button.html();
    // disable the button and show a loading icon
    button.prop('disabled', true);
    button.html('<i class="fa fa-spinner fa-spin"></i>');

    // re-enable the button after 1 second
    setTimeout(function () {
      button.prop('disabled', false);
      button.html(html);
      form.dispatchEvent(submitEvent);

    }, 1000);
  });

  // Data overlay trigger
  $('[data-overlay]').on('click', e => {
    e.preventDefault();
    const value = $(e.currentTarget).attr('data-overlay');
    const selector = $(e.currentTarget).attr('data-overlay-element');
    const content = $(selector).html();
    const callback = $(e.currentTarget).attr('data-overlay-callback');

    // Delete actions
    overlayDeleteList.forEach(element => {
      if (element == value) deleteConfirmationOverlay(content);
    });

    // Confirm action
    overlayConfirmList.forEach(element => {
      if (element == value) confirmOverlay(content);
    });

    // Form actions
    overlayFormList.forEach(element => {
      if (element == value) {
        let form = formOverlay(content);
        form.then(() => {
          if (callback)
            callbacks[callback]();
        });
      }
    }, this);
  });

  // Data info trigger
  $('[data-info]').on('click', e => {
    e.preventDefault();
    const value = $(e.currentTarget).attr('data-info');
    const selector = $(e.currentTarget).attr('data-overlay-element');
    const content = $(selector).html();

    infoOverlay(content);
  });

  /* Activate element on or off:
      usage: trigger element data-switch=" @#id of element " */
  $('[data-activator]').on('click', e => {
    e.preventDefault();
    let element_id = $(e.currentTarget).attr('data-activator');
    activator.set(element_id);
  });

  /* Submit form */
  $('body').on('click', '[data-submit]', e => {
    e.preventDefault();
    const form_id = $(e.currentTarget).attr('data-submit');
    // check if string got char
    const hasChar = stringHasChar({char: '#', str: form_id});
    const id = hasChar
      ? form_id
      : "#" + form_id;

    $(id).submit();
  });


  $('[data-filter]').on('click', e => {
    e.preventDefault();
    const filter_id = $(e.currentTarget).attr('data-filter');
    filter.set(filter_id);
  });

  $('.filter-list').on('click', 'a', e => {
    e.preventDefault();
    const filter_id = '#filter';
    filter.set(filter_id);
  });

  $('[data-default-link]').on('click', e => {

    if (!$(e.currentTarget).is('a') && !$(e.currentTarget).is('i')) {
      e.preventDefault();
      windowLocation($(e.currentTarget).attr('data-default-link'));
    }
  });

  $('div.dropdown-item.filter-item').on('click', e => {
    e.preventDefault();
    const val = $(e.currentTarget).data('value');
    const data = $(e.currentTarget).data('name');
    const div = `.dropdown[data-name="${data}"]`;
    if (Number.isInteger(val)) {
      $(`input[data-name="${data}"]`).val(val);
    } else {
      $(`input[data-name="${data}"]`).val('');
    }
    $(`${div} #${data}`).html(`${data}: ` + val);
    setTimeout(() => $('#filterform').submit(), 500)
  });

  // Counts form, with confirmation
  body.on('click', '#countsUpdateTrigger', e => {
    e.preventDefault();
    const form = $('#countsFormEdit');
    const confirmInput = $(form).find('input[name="confirm"]');
    confirmInput.val(1);
    form.submit();
  });

  // Order form, with confirmation
  body.on('click', '#orderUpdateTrigger', e => {
    e.preventDefault();
    const form = $('#orderFormEdit');
    const confirmInput = $(form).find('input[name="confirm"]');
    confirmInput.val(1);
    form.submit();
  });

  // Order form, with confirmation
  body.on('click', '#correctionUpdateTrigger', e => {
    e.preventDefault();
    const form = $('#correctionEditForm');
    const confirmInput = $(form).find('input[name="confirm"]');
    confirmInput.val(1);
    form.submit();
  });

  // Sale form, with confirmation
  body.on('click', '#saleUpdateTrigger', e => {
    e.preventDefault();
    // let action = e.attr
    const form = $('#salesFormEdit');
    $(form).attr('action', e.currentTarget.getAttribute('data-action'));
    const confirmInput = $(form).find('input[name="confirm"]');
    const methodForm = $(form).find('input[name="_method"]');
    confirmInput.val(1);
    methodForm.val('POST');
    form.submit();
  });


  // Scroll down when 'Beschikbare Artikelen' is clicked
  $('.add-article').on('click', () => {
    let page = $('html, body');

    // interrupt animation on scroll
    page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", () => {
      page.stop();
    });

    page.animate({
      scrollTop: $('.add-article-form').offset().top - 250
    }, 1500);
  });

  $('.order-print').on('click', () => {
    $('#order-current-action').val('print');
  });

  // $.each($('.purchase-quantity'), (key, value) => {
  //     if (!$(this).val()) {
  //         $('.icon' + key).css('display', 'none');
  //     }
  // });

  // Sidebar
  $(".sidebar-dropdown > a").click(() => {
    $(".sidebar-submenu").slideUp(200);
    if ($(this).parent().hasClass("active")) {
      $(".sidebar-dropdown").removeClass("active");
      $(this).parent().removeClass("active");
    } else {
      $(".sidebar-dropdown").removeClass("active");
      $(this).next(".sidebar-submenu").slideDown(200);
      $(this).parent().addClass("active");
    }

  });

  $(".page-wrapper").addClass("pinned");
  $("#sidebar").hover(
    () => {
      $(".page-wrapper").addClass("sidebar-hovered");
    },
    () => {
      $(".page-wrapper").removeClass("sidebar-hovered");
    }
  );

  $("#overlay").click(() => {
    $(".page-wrapper").toggleClass("toggled");
  });

  $('.la.la-angle-left').attr('class', 'fa fa-angle-left');
  $('.la.la-angle-right').attr('class', 'fa fa-angle-right');

  filterList.set();
  counter.set();
  multivalue.set();
})
;
