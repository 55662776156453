const getAttribute = (attr) => {
    return (element) => {
        return $(element).attr(attr);
    }
}

const createElement = (type) => {
    return (options) => {
        return $(type, options);
    }
}

const createTrigger = (text) => {
    let a = createElement('<a/>'); 
    return a({'text': text}); 
}

const createInputs = (value) => {
    return (name) => {
        let input = createElement('<input/>'); 
        return input({type: 'hidden', value: value, name: name }); 
    }
}

const appendTo = (element) => {
    return (source) => {
        $(source).appendTo(element); 
    }
}

const removeItem = (e) => {
    e.preventDefault();

    let element = $(e.target); 
    let text = element.text();
    let input = $(e.delegateTarget).find('input[type="hidden"]')
        .toArray()
        .reduce((current, element) => { 
            if($(element).val() == text) 
                current = $(element);
            return current; 
        },false);
    
    $(element).remove();
    $(input).remove();
}

const addItem = (element) => {
    return () => {
        // Get user value
        let input = $(element).find('.input-box input'); 
        let value = $(input).val();
        
        // get input name
        let getInputName = getAttribute('data-input-name');

        let nameValue = getInputName(element);
        
        // Has a value 
        if(value){ 
            // Create new hidden element with value
            let hiddenInput = createInputs(value)(nameValue);
            let trigger = createTrigger(value);
            let addToValuesElement = appendTo($(element).find('.values')); 
            let addToFormElement = appendTo(element);
            
            // Add to html
            addToValuesElement(trigger);
            addToFormElement(hiddenInput);
            
            // Remove current input
            $(input).prop('value', '');
        }
    }
}

const multiInit = (element) =>
{
    // Get current values and create a list
    const getInputValues = getAttribute('value');
    const addToValuesElement = appendTo($(element).find('.values')); 
    const list = $(element).find('input[type="hidden"]')
        .toArray()
        .map(getInputValues);

    const createNewValue = addItem(element);
        
    // Append triggers to .values div
    list.map(createTrigger)
        .map(addToValuesElement);

    const addItemTrigger = $(element).find('.input-box a'); 
    const addItemInputTrigger = $(element).find('.input-box input'); 
    const addDefaultNameTrigger = $(element).find('#default_name');

    // Listeners
    $(element).on('click', '.values a', removeItem); 
    addItemTrigger.on('click', createNewValue);
    addItemInputTrigger.on('change', createNewValue);
    addDefaultNameTrigger.on('change', createNewValue);
}

const multiValue = {
    set: () => {
        let target = $('.addmultiple').toArray();
        target.forEach(multiInit);
    }
}

module.exports = multiValue;