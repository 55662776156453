const filterListId = '#filter-list-content';

const appendTo = (element) => {
	return (content) => {
		if($(element).length)
			$(content).appendTo(element);
	}
}

const filterListFormat = (name) => {
	return (element) => {
		return {
			name: name,
			value: $(element).val(),
			element: element
		}
	}
}

const typeCheck = (type) => {
	return (element) => {
		return $(element).is(type);
	}
}

const flattenArray = (arr) => {
	return arr.reduce( (flat, toFlatten) => {
		return flat.concat(Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten);
	}, []);
}

const filterValues = (element) => {
	const typeInput = typeCheck('input');
	const typeSelect = typeCheck('select');

	let name = $(element).attr('data-filter-label');
	let format = filterListFormat(name);

	// checkbox
	if(typeInput(element)){
		return format(element);
	}

	// select
	if(typeSelect(element)) {
		let options = $(element).find('option:selected').toArray();
		return options.map(format);
	}
}

const createHtmlElement = (type) => {
	return (options) => {
		return $(type, options);
	}
}

const createLinkElement = (type) => {
	return (element) => {
		return createHtmlElement(type)({
				href: '#',
				text: element.name
			}
		);
	}
}

const filterList = {

	set: () =>
	{
		// Get all input/select elements with data-filter-label
		const list = $('.filter input[data-filter-label]:checked ,.filter select[data-filter-label]').toArray();

		// Get all Values from all form elements, and flat them
		const values = flattenArray(list.map(filterValues));
		const appendToContainer = appendTo($(filterListId));

		// Create links
		const contentLinkElement = createLinkElement('<a/>');
		const linkList = values.map(contentLinkElement);

		// Append links to container
		linkList.map(appendToContainer);

		if(linkList.length)
			$(filterListId).parent().parent().addClass('active');
	}
}

module.exports = filterList;